import http from '@/utils/http'
// 合同管理查询
export const getContractPage = p => http.post('/unibu/contract/page', p)

// 添加修改合同
export const addModContract = p => http.post('/unibu/contract/addMod', p)

// 合同单条详情
export const gainContractOne = p => http.post('/unibu/contract/one', p)
// 逻辑删除合同
export const delContract = p => http.post('/unibu/contract/del', p)

// 导出合同
export const exportExcelContract = p => http.postFormExcel('/unibu/contract/exportExcel', p)

// 关联客户接口
export const getRelaList = p => http.post('/unibu/rela/getRelaList', p)

// 关联工程接口
export const getProjList0 = p => http.post('/unibu/proj/list0', p)

// 关联工程接口
export const getProjList = p => http.post('/unibu/proj/list', p)

// 新增工程接口
export const addProj = p => http.post('/unibu/proj/add', p)

// 合同状态数据字典
export const gainContractStatus = p => http.get('/unibu/dd/base_contract_status', p)

// 合同类型数据字典
export const gainContractType = p => http.get('/unibu/dd/base_contract_type', p)

// 合同定价方式
export const gainpriceType = p => http.get('/unibu/dd/price_type', p)
