import http from '@/utils/http'
//
// 工程列表-多表
export const getProjecctList = p => http.post('/unibu/proj/page', p)

// 工程单条详情-多表
export const getProjecctOne = p => http.post('/unibu/proj/one', p)

// 工程删除-多表
export const deleteProjecct = p => http.post('/unibu/proj/del', p)

// 工程调整(新增或修改)-多表
export const setProjecct = p => http.post('/mortconc/projcs/setm', p)

// 工程 输入工程名称、项目类型/工程类型时调用这个接口回填
export const gainProjCallback = p => http.post('/unibu/proj/callback', p)

// 无条件获取客户信息
export const getRelaList = p => http.post('/unibu/rela/getRelaList', p)

// 通过企业统一信用码或者企业全名获取客户信息
export const getRelaOne = p => http.post('/unibu/rela/getRelaOne', p)

// 工程新增
export const addProj = p => http.post('/unibu/proj/add', p)

// 工程新增
export const modProj = p => http.post('/unibu/proj/mod', p)
