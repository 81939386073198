<template>
  <el-dialog  v-bind="{title:editData?'编辑工程':'新建工程',width:'860px'}"
              @open="handlerOpen"
              @closed="handlerClose"
              :visible.sync="visibleDialog">

    <el-form :inline="true" ref="form" size="mini" label-position="right" label-width="200px"
             :rules="rules"
             :model="submitForm"
             style="margin-top: 20px">
      <el-row>
        <el-form-item label="供应商:" prop="supplier">
          <el-select
            v-model="submitForm.supplierId"
            placeholder="请选择供应商"
            filterable
            clearable
            style="width: 180px"
          >
            <template v-for="item in supplierList">
              <el-option
                v-if="typeof(submitForm.supplierId) =='string'"
                :key="item.supplierId"
                :label="item.supplier"
                :value="''+item.supplierId">
              </el-option>
              <el-option
                v-else-if="typeof(submitForm.supplierId) =='number'"
                :key="item.supplierId"
                :label="item.supplier"
                :value="((''+item.supplierId).trim() == '')?'':parseInt(item.supplierId)">
              </el-option>
              <el-option
                v-else
                :key="item.supplierId"
                :label="item.supplier"
                :value="item.supplierId">
              </el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="采购计划数量(吨):" prop="planNum">
          <el-input v-model="submitForm.planNum">
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="生产厂家：">
          <el-input v-model="submitForm.factory" style="width: 180px">
          </el-input>
        </el-form-item>
        <el-form-item label="送货日期:">
          <el-date-picker
            v-model="submitForm.sendDate"
            type="datetime"
            placeholder="选择日期时间"
            default-time="00:00:00"
            value-format="yyyy-MM-dd"
            style="width: 180px"
          />
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="材料名称：" prop="materialName">
          <el-input v-model="submitForm.materialName" style="width: 180px">
          </el-input>
        </el-form-item>
        <el-form-item label="品种规格：">
          <el-input v-model="submitForm.model" style="width: 180px">
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="采购计划数量(吨):" prop="planNum">
          <el-input v-model="submitForm.planNum">
          </el-input>
        </el-form-item>
        <el-form-item label="供应商:" prop="supplier">
          <el-input v-model="submitForm.supplier">
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="收货企业:" prop="receivEnterprise">
          <el-input v-model="submitForm.receivEnterprise">
          </el-input>
        </el-form-item>
        <el-form-item label="收货站点:">
          <el-input v-model="submitForm.receivSite">
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="订单日期:">
          <el-date-picker
            v-model="submitForm.creatDate"
            type="datetime"
            placeholder="选择日期时间"
            default-time="00:00:00"
            value-format="yyyy-MM-dd"
            style="width: 180px"
          />
        </el-form-item>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visibleDialog = false" size="mini">取消</el-button>
      <el-button type="primary" @click="submit" size="mini">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import http from '@/utils/http'
import { addProj, modProj } from '@/apis/priceManage/projectMan'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
      require: true
    },
    editData: {
      type: Object
    },
    relaItems: {
      type: Array,
      default () {
        return []
      }
    },
    regionOptions: {
      type: Array,
      default () {
        return []
      }
    },
    regionProps: {
      type: Object
    },
    regionArr: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    visibleDialog: {
      set (newValue) {
        this.$emit('update:visible', newValue)
      },
      get () {
        return this.visible
      }
    }
  },
  data () {
    return {
      projectItems: [],
      http: http,
      url: http.baseUrl + '/basefun/fdfscs/uploadOne',
      loading: false,
      relaTypeOptions: {
        1: '建设',
        2: '设计',
        3: '施工',
        4: '监理',
        5: '质监'
        /*  10: '企业下客户',
          20: '企业下供应商',
          30: '机构下质检',
          40: '保险公司',
          50: '设备供应商' */
      },
      supplierList: [],
      submitForm: {
        supplier: '',
        supplierId: '',
        projName: '',
        projNum: '',
        coveArea: 0,
        aboveArea: 0,
        address: '',
        builFirm: '',
        complDat: '',
        concrete: 0,
        consLiceNum: '',
        imgURL: '',
        initDat: '',
        lastConsFirm: '',
        lastLinkName: '',
        lastLinkPhone: '',
        opertime: '',
        projId: '',
        on: true,
        price: 0,
        projType: '',
        regiCountrId: '',
        regiCityId: '',
        regiProvinId: '',
        countrName: '',
        cityName: '',
        provinName: '',
        relaType: '',
        coverArea: 0,
        relaList: [
        ],
        projMonList: [
        ]
      },
      rules: {
        projName: [
          { required: true, message: '请输入项目名称', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    addUnitRow () {
      this.submitForm.relaList.push({
        linkName: '',
        linkPhone: '',
        linkCard: '',
        firm: '',
        relaType: '',
        uniteCredit: '',
        accoId: ''
      })
    },
    insertMonEvent () {
      this.submitForm.projMonList.push({
        monName: '',
        monOther: '',
        monParam: '',
        projId: '',
        projMonId: ''
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      row.row_index = rowIndex
    },
    onRowClick (row, event, column) {
      this.currentRelaIndex = row.row_index
    },
    handleItemChange (val) {
    },
    queryProjSearch (queryString, cb) {
      const projectItems = this.projectItems
      const results = queryString ? projectItems.filter(item => (item.prjname.indexOf(queryString) > -1)) : projectItems
      // 调用 callback 返回建议列表的数据
      cb(results.splice(0, (results.length > 30 ? 30 : results.length)))
    },
    handleProjSelect (item) {
      this.submitForm.address = item.address
      this.submitForm.projNum = item.prjnum
      this.submitForm.coveArea = item.allarea
      this.submitForm.coveArea = item.allarea
      this.submitForm.regiCountrId = item.regiCountrId
      this.submitForm.regiCityId = item.regiCityId
      this.submitForm.regiProvinId = item.regiProvinId
    },
    queryRelaSearch (queryString, cb) {
      const relaItems = this.relaItems
      const results = queryString ? relaItems.filter(item => (item.uniteCredit.indexOf(queryString) > -1)) : relaItems
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    handleRelaSelect (rela) {
      const curIndex = this.currentRelaIndex
      this.submitForm.relaList[curIndex].relaType = rela.relaType.toString()
      this.submitForm.relaList[curIndex].firm = rela.firm
      this.submitForm.relaList[curIndex].linkName = rela.linkName
      this.submitForm.relaList[curIndex].linkPhone = rela.linkPhone
    },
    // 上传图片
    uploadPicture (content) {
      this.loading = true
      const formData = new FormData()
      formData.append('file', content.file)
      http.postForm(this.url, formData).then((res) => {
        this.submitForm.imgURL = res.data.nowRelatUrl
        // this.loading = false
      })
    },

    uploadSuccess () {
      console.log('uploadSuccess')
      this.reload = true
      this.$nextTick(() => {
        this.reload = false
      })
    },
    removeUnitRow (index, rows) { // 删除改行
      this.$confirm('您确定要删除该数据?', '删除数据', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        rows.splice(index, 1)
      })
    },
    removeMonRow (index, rows) { // 删除改行
      this.$confirm('您确定要删除该数据?', '删除数据', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        rows.splice(index, 1)
      })
    },
    refreshProjCallback () {
    },
    submit () {
      const pathLabels = this.$refs.editCascader.getCheckedNodes()[0] ? this.$refs.editCascader.getCheckedNodes()[0].pathLabels : []
      const param = {
        projName: this.submitForm.projName,
        projNum: this.submitForm.projNum,
        coveArea: this.submitForm.coveArea,
        aboveArea: this.submitForm.aboveArea,
        address: this.submitForm.address,
        complDat: this.submitForm.complDat,
        mortar: this.submitForm.mortar,
        consLiceNum: this.submitForm.consLiceNum,
        imgURL: this.submitForm.imgURL,
        initDat: this.submitForm.initDat,
        lastLinkName: this.submitForm.lastLinkName,
        lastLinkPhone: this.submitForm.lastLinkPhone,
        opertime: this.submitForm.opertime,
        projId: this.submitForm.projId,
        on: true,
        price: this.submitForm.price,
        regiCountrId: this.regionArr[2] ? this.regionArr[2] : undefined,
        regiCityId: this.regionArr[1] ? this.regionArr[1] : undefined,
        regiProvinId: this.regionArr[0] ? this.regionArr[0] : undefined,
        countrName: pathLabels[2] ? pathLabels[2] : undefined,
        cityName: pathLabels[1] ? pathLabels[1] : undefined,
        provinName: pathLabels[0] ? pathLabels[0] : undefined,
        relaList: this.submitForm.relaList,
        projType: this.submitForm.projType,
        projMonList: this.submitForm.projMonList,
        underArea: this.submitForm.underArea
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.editData) {
            modProj(param).then(res => {
              if (res.code === 200) {
                if (this.submitForm.projId != '') {
                  this.$message.success('修改成功！')
                } else {
                  this.$message.success('添加成功！')
                }
                this.visibleDialog = false
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            addProj(param).then(res => {
              if (res.code === 200) {
                if (this.submitForm.projId != '') {
                  this.$message.success('修改成功！')
                } else {
                  this.$message.success('添加成功！')
                }
                this.visibleDialog = false
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        }
      })
    },

    handlerOpen () {
      if (this.editData) {
        Object.keys(this.submitForm).forEach((key) => {
          const value = this.editData[key]
          if (value) this.submitForm[key] = value
        })
      }
    },

    handlerClose () {
      this.$emit('update:editData', null)
      this.submitForm = {
        projName: '',
        projNum: '',
        coveArea: '',
        aboveArea: '',
        address: '',
        builFirm: '',
        complDat: '',
        consLiceNum: '',
        imgURL: '',
        initDat: '',
        lastConsFirm: '',
        lastLinkName: '',
        lastLinkPhone: '',
        opertime: '',
        projId: '',
        on: true,
        price: 0,
        regiCountrId: '',
        regiCityId: '',
        regiProvinId: '',
        countrName: '',
        cityName: '',
        provinName: '',
        projType: '',
        relaType: '',
        mortar: '',
        underArea: '',
        relaList: [
        ],
        projMonList: [
        ]
      }
      this.$refs.form.resetFields()
    }
  },
  mounted () {
    this.refreshProjCallback()
    // 20:企业下供应商
  }
}
</script>
<style lang="scss" scoped>
.show_imgUrl {
  max-width: 95px;
  max-height: 60px;
}
</style>
