<template>
  <page-container>
    <div class="layout">
      <div style="width: 100%;display: flex;align-items: center;">
        <el-form  :inline="true" :model="searchModel" style="margin-top: 20px;padding-left: 20px" size="mini">
          <el-form-item label="出发日期:">
            <el-date-picker
              v-model="searchModel.startTimeStart"
              type="datetime"
              placeholder="选择日期时间"
              style="width: 180px"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
            --
            <el-date-picker
              v-model="searchModel.startTimeEnd"
              type="datetime"
              placeholder="选择日期时间"
              style="width: 180px"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
          <el-form-item label="车牌号:" prop="vehiId">
            <el-select
              v-model="searchModel.plateNumber"
              @change='plateNumberChange'
              placeholder="请选择车牌号"
              filterable
              clearable
              style="width: 200px"
            >
              <template v-for="item in busLicPlateList">
                <el-option
                  v-if="typeof(item.vehiId) =='string'"
                  :key="item.vehiId"
                  :label="item.busLicPlate"
                  :value="''+item.vehiId">
                </el-option>
                <el-option
                  v-else-if="typeof(item.vehiId) =='number'"
                  :key="item.vehiId"
                  :label="item.busLicPlate"
                  :value="((''+item.vehiId).trim() == '')?'':parseInt(item.vehiId)">
                </el-option>
                <el-option
                  v-else
                  :key="item.vehiId"
                  :label="item.busLicPlate"
                  :value="item.vehiId">
                </el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item label="品种规格:" prop="model">
            <el-input v-model="searchModel.model"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button  type="primary" icon="el-icon-search" @click="submitSearch" :loading="loading">
              {{ searchText }}
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-close" v-if="loading" @click="loading = false">
              停止
            </el-button>
          </el-form-item>

          <el-form-item>
            <el-button
              v-auth="'purchaseManage_purchaseAppointmentOrder_add'"
              type="primary"
              icon="el-icon-circle-plus-outline"
              @click="dialogEdit = true;purcEditModel = undefined"
            >新增采购预约单
            </el-button>
          </el-form-item>
        </el-form>
      </div>

      <resize-wrap class="flex-1">
        <BaseTable
          id="purchaseAppointmentOrder"
          ref="xTable1"
          size="mini"
          border
          auto-resize
          resizable
          highlight-current-row
          highlight-hover-row
          export-config
          :align="allAlign"
          :seq-config="{startIndex: (tablePage.currentPage - 1) * tablePage.pageSize}"
          :data="tableData">
          <vxe-table-column type="seq" title="序号" width="60"/>
          <vxe-table-column field="reservationId" title="预约单号" show-overflow="title" min-width="100"
                            show-header-overflow="title"/>
          <vxe-table-column field="waybillNumber" title="运单号" show-overflow="title" min-width="100"
                            show-header-overflow="title"/>
          <vxe-table-column field="firm" title="供货厂商" show-overflow="title" min-width="120"
                            show-header-overflow="title"/>
          <vxe-table-column field="carrier" title="承运商" show-overflow="title" min-width="120"
                            show-header-overflow="title"/>
          <vxe-table-column field="supplier" title="供应商" show-overflow="title" min-width="120"
                            show-header-overflow="title"/>

          <vxe-table-column field="driver" title="驾驶员" show-overflow="title" min-width="120"
                            show-header-overflow="title"/>
          <vxe-table-column field="plateNumber" title="车牌号" show-overflow="title" min-width="120"
                            show-header-overflow="title"/>
          <vxe-table-column field="materialName" title="材料名称" show-overflow="title" min-width="120"
                            show-header-overflow="title"/>
          <vxe-table-column field="model" title="品种规格" show-overflow="title" min-width="120"
                            show-header-overflow="title"/>
          <vxe-table-column field="receivEnterprise" title="收货企业" show-overflow="title" min-width="120"
                            show-header-overflow="title"/>
          <vxe-table-column field="receivSite" title="收货地点" show-overflow="title" min-width="120"
                            show-header-overflow="title"/>
          <vxe-table-column field="roughWeight" title="毛重自动" show-overflow="title" min-width="120"
                            show-header-overflow="title"/>
          <vxe-table-column field="roughWeight2" title="毛重调整" show-overflow="title" min-width="120"
                            show-header-overflow="title"/>
          <vxe-table-column field="roughWeightTime" title="毛重时间" show-overflow="title" min-width="120"
                            show-header-overflow="title"/>

          <vxe-table-column field="startTime" title="出发时间" min-width="100" show-overflow="title"
                            show-header-overflow="title"/>
          <vxe-table-column field="planArrivalTime" title="预计到达时间" min-width="100" show-overflow="title"
                            show-header-overflow="title"/>
          <vxe-table-column field="unloadStartTime" title="开始卸料时间" min-width="100" show-overflow="title"
                            show-header-overflow="title"/>
          <vxe-table-column field="unloadEndTime" title="卸料完成时间" min-width="100" show-overflow="title"
                            show-header-overflow="title"/>
          <vxe-table-column field="currentStatus" title="运单状态" min-width="100" show-overflow="title"
                            show-header-overflow="title"><template #default="{ row }"><span>{{ formatStatus(row.currentStatus) }}</span></template></vxe-table-column>
          <vxe-table-column field="state" title="签收状态" min-width="100" show-overflow="title" show-header-overflow="title" ><template #default="{ row }"><span>{{ formatSex(row.state) }}</span></template></vxe-table-column>

          <vxe-table-column field="voucherNowRelativeUrls" title="附件" min-width="60" show-overflow="title"
                            show-header-overflow="title" />
          <vxe-table-column title="操作" min-width="160">
            <template v-slot="{ row }">
              <el-button v-auth="'purchaseManage_purchaseAppointmentOrder_detail'" type="success" icon="el-icon-tickets" circle size="mini" @click="editAble=false;detailRow(row)" title="详情"/>
              <el-button v-auth="'purchaseManage_purchaseAppointmentOrder_edit'" type="primary" icon="el-icon-edit" circle size="mini" @click="editAble=true;detailUpdateRow(row)" title="修改"/>
              <el-button v-auth="'purchaseManage_purchaseAppointmentOrder_delete'" type="danger" icon="el-icon-delete" circle size="mini" @click="deleteRow(row)" title="删除"/>
            </template>
          </vxe-table-column>
        </BaseTable>
      </resize-wrap>

      <vxe-pager
        :loading="loading"
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :total="tablePage.total"
        :layouts="['Sizes','PrevPage','JumpNumber','NextPage','FullJump','Total']"
        @page-change="handlePageChange"
      />
      <!-- 编辑采购预约单 -->
      <purchaseAppointmentOrderDetail :visible.sync="dialogEdit" :edit-data.sync="purcEditModel" @refresh="refreshData"  :region-options="regionOptions" :region-props="regionProps" :edit-able ="editAble"/>
      <!-- 采购预约单详情 -->
      <purchaseAppointmentOrderMoreDetail :visible.sync="dialogDetail" :edit-data.sync="purcDetailModel" @refresh="refreshData"  :region-options="regionOptions" :region-props="regionProps" />
    </div>
  </page-container>
</template>

<script>

// import XEUtils from 'xe-utils'
// import {exportToxlsx} from '@/utils/tools'

import purchaseAppointmentOrderDetail from './purchaseAppointmentOrderDetail'

import purchaseAppointmentOrderMoreDetail from './purchaseAppointmentOrderMoreDetail'

import { getreservationManagepage, getreservationManageone, delreservationManage, getgdtSupplier2, getbusLicPlatelist } from '@/apis/purchaseManage/purchaseManage'
import moment from 'moment'

export default {
  name: 'purchaseAppointmentOrder',
  data () {
    return {
      editAble: true,
      searchModel: {
        plateNumber: '',
        model: '',
        startTimeStart: '',
        startTimeEnd: ''
      },
      busLicPlateList: [],
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 30,
        align: 'right',
        pageSizes: [10, 20, 50, 100, 200, 500],
        layouts: ['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total'],
        perfect: true
      },
      searchText: '查询',
      allAlign: 'center',
      tableData: [],
      regionArr: [],
      regionOptions: [],
      regionProps: {
        // multiple: true,
        checkStrictly: true,
        lazy: true,
        lazyLoad (node, resolve) {
          const level = node.level
          if (level === 1) {
            resolve(node.children[0].label == '直辖区' ? [] : node.children)
            // resolve(node.children)
          }
          if (level === 2 && node.data.code) {
            if (node.children && node.children.lenght > 0) {
              resolve(node.children)
            } else {
              setTimeout(() => {
              })
            }
          } else if (level == 3) {
            resolve([])
          }
        }
      },
      editMode: 'add',
      projDetailModel: {},
      // 所属区域
      selectedOptions: [],
      loading: false,
      dialogEditTitle: '新增采购预约单',
      dialogEdit: false,
      purcEditModel: {},
      dialogDetail: false,
      purcDetailModel: {},
      supplierItems: ''
    }
  },
  methods: {
    formatSex (value) {
      if (value == '2') {
        return '正常'
      }
      if (value == '0') {
        return '拒收'
      }
      if (value == '1') {
        return '退货'
      }
      if (value == '3') {
        return '作废'
      }
      return ''
    },
    formatStatus (value) {
      if (value == '1') {
        return '出发'
      }
      if (value == '3') {
        return '进厂'
      }
      if (value == '4') {
        return '开始卸料'
      }
      if (value == '5') {
        return '卸料结束'
      }
      if (value == '6') {
        return '出厂'
      }
      return ''
    },
    handleItemChange (val) {
    },
    // 分页变化
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage
      this.tablePage.pageSize = pageSize
      this.refreshData()
    },
    submitSearch () {
      const params = {
        startTimeStart: this.searchModel.startTimeStart,
        startTimeEnd: this.searchModel.startTimeEnd
      }

      // if (!params.startTimeStart) {
      //   this.$message({
      //     message: '请选择开始时间',
      //     type: 'warning'
      //   })
      //   return
      // }
      // if (!params.startTimeEnd) {
      //   this.$message({
      //     message: '请选择结束时间',
      //     type: 'warning'
      //   })
      //   return
      // }
      const startTimeStart = moment(params.startTimeStart)
      const startTimeEnd = moment(params.startTimeEnd)
      const diffSecond = startTimeEnd.diff(startTimeStart, 'seconds')
      if (diffSecond < 0) {
        this.$message({
          message: '开始时间不能晚于结束时间',
          type: 'warning'
        })
        return
      }
      // if (diffSecond > 86400 * 31) {
      //     this.$message({
      //         message: '查询时间段不能超过31天',
      //         type: 'warning'
      //     });
      //     return;
      // }
      this.loading = true
      this.tableData = []
      this.tablePage.currentPage = 1
      this.refreshData()
    },
    async plateNumberChange (val) {
      console.log(JSON.stringify(val) + '车牌号vehiId的数据')
      for (let i = 0; i < this.busLicPlateList.length; i++) {
        if (val == this.busLicPlateList[i].vehiId) {
          console.log(JSON.stringify(this.busLicPlateList[i].plateNumber) + '车牌号的数据')
          this.searchModel.plateNumber = this.busLicPlateList[i].busLicPlate
        }
      }
    },
    getgdtSupplier2List () {
      // 关联供应商
      getgdtSupplier2().then(res => {
        console.log(666, res)
        const suppliItems = []
        res.data.forEach(item => {
          const temp = item
          temp.value = item.supplierName
          suppliItems.push(temp)
        })
        this.supplierItems = suppliItems
      })
    },
    refreshData () {
      this.loading = true
      console.log(JSON.stringify(this.userName) + 'handlerOpen打印purchaseAppointmentOrder+this.userName')
      getreservationManagepage({
        ...this.searchModel,
        pageSize: this.tablePage.pageSize,
        pageNum: this.tablePage.currentPage
      }).then(res => {
        this.loading = false
        if (res.code == 200) {
          this.tableData = res.data.rows
          this.tablePage.total = res.data.totalSize
        } else {
          this.tableData = []
          this.tablePage.total = 0
        }
      }).catch(error => {
        console.log(error)
        this.logining = false
      })
    },
    detailRow (row) {
      getreservationManageone({ id: row.reservationId }).then(res => {
        const details = res.data
        const rowData = {}

        XEUtils.objectEach(details, (item, key) => {
          rowData[key] = details[key] ? details[key].toString() : ''
        })
        this.purcDetailModel = Object.assign({}, rowData)
        this.dialogDetail = true
      })
    },
    detailUpdateRow (row) {
      getreservationManageone({ id: row.reservationId }).then(res => {
        const details = res.data
        const rowData = {}

        XEUtils.objectEach(details, (item, key) => {
          rowData[key] = details[key] ? details[key].toString() : ''
        })
        this.purcEditModel = Object.assign({}, rowData)
        this.dialogEdit = true
      })
    },
    deleteRow (row) {
      this.$confirm('确定删除该预约单记录?', '删除预约单记录', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        delreservationManage({
          ids: [row.reservationId]
        }).then(res => {
          if (res.code === 200) {
            this.$message.success('删除成功！')
            this.refreshData()
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    }
  },
  mounted () {
    this.refreshData()
    getbusLicPlatelist().then(res => {
      this.busLicPlateList = res.data.rows
    })
  },
  components: {
    purchaseAppointmentOrderDetail,
    purchaseAppointmentOrderMoreDetail
  }

}
</script>

<style scoped>

</style>
