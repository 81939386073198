<template>
  <el-dialog v-bind="{title:dialogTitle,width:'860px'}"
             @open="handlerOpen"
             @close="handlerClose"
             :visible.sync="visibleDialog">
    <el-form :inline="true" ref="addForm" size="mini" label-position="right"
             label-width="200px"
             :rules="rules"
             :model="submitForm"
             style="margin-top: 20px"
    >
      <el-row>
        <el-form-item label="订单编号：" prop="orderNumber">
          <el-input disabled v-model="submitForm.orderNumber" style="width: 180px">
          </el-input>
        </el-form-item>
        <el-form-item label="订单日期：" prop="creatDate">
          <el-input disabled v-model="submitForm.creatDate" style="width: 180px">
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="品种规格：" prop="model">
          <el-input disabled v-model="submitForm.model" style="width: 180px">
          </el-input>
        </el-form-item>
        <el-form-item label="供应商：" prop="supplier">
          <el-input v-model="submitForm.supplier" style="width: 180px">
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="供货厂商：" prop="compId">
          <el-select
            v-model="submitForm.firm"
            @change="firmChange"
            placeholder="请选择供货厂商"
            filterable
            clearable
            style="width: 180px"
          >
            <template v-for="item in firmList">
              <el-option
                v-if="typeof(item.compId) =='string'"
                :key="item.compId"
                :label="item.compName"
                :value="''+item.compId">
              </el-option>
              <el-option
                v-else-if="typeof(item.compId) =='number'"
                :key="item.compId"
                :label="item.compName"
                :value="((''+item.compId).trim() == '')?'':parseInt(item.compId)">
              </el-option>
              <el-option
                v-else
                :key="item.compId"
                :label="item.compName"
                :value="item.compId">
              </el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="承运商：" prop="carrier">
          <el-input v-model="submitForm.carrier" style="width: 180px">
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="收货企业:" prop="receivEnterprise">
          <el-input v-model="submitForm.receivEnterprise" style="width: 180px">
          </el-input>
        </el-form-item>
        <el-form-item label="收货站点:" prop="receivSite">
          <el-input disabled v-model="submitForm.receivSite" style="width: 180px">
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="驾驶员:" prop="persId">
          <el-select
            v-model="submitForm.persId"
            placeholder="请选择驾驶员"
            @change="driverChange"
            filterable
            clearable
            style="width: 180px"
          >
            <template v-for="item in driverList">
              <el-option
                v-if="typeof(item.persId) =='string'"
                :key="item.persId"
                :label="item.persName"
                :value="''+item.persId">
              </el-option>
              <el-option
                v-else-if="typeof(item.persId) =='number'"
                :key="item.persId"
                :label="item.persName"
                :value="((''+item.persId).trim() == '')?'':parseInt(item.persId)">
              </el-option>
              <el-option
                v-else
                :key="item.persId"
                :label="item.persName"
                :value="item.persId">
              </el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号:" prop="driverPhone">
          <el-input disabled v-model="submitForm.driverPhone" style="width: 180px">
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="预计到达时间:" prop="planArrivalTime">
          <el-date-picker
            v-model="submitForm.planArrivalTime"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            style="width: 180px"
          />
        </el-form-item>
        <el-form-item label="车牌号:" prop="plateNumber">
          <el-select
            v-model="submitForm.plateNumber"
            @change='plateNumberChange'
            placeholder="请选择车牌号"
            filterable
            clearable
            style="width: 180px"
          >
            <template v-for="item in busLicPlateList">
              <el-option
                v-if="typeof(item.vehiId) =='string'"
                :key="item.vehiId"
                :label="item.busLicPlate"
                :value="''+item.vehiId">
              </el-option>
              <el-option
                v-else-if="typeof(item.vehiId) =='number'"
                :key="item.vehiId"
                :label="item.busLicPlate"
                :value="((''+item.vehiId).trim() == '')?'':parseInt(item.vehiId)">
              </el-option>
              <el-option
                v-else
                :key="item.vehiId"
                :label="item.busLicPlate"
                :value="item.vehiId">
              </el-option>
            </template>
          </el-select>

        </el-form-item>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog__footer" >
      <el-button @click="visibleDialog=false" size="mini">取消</el-button>
      <el-button type="primary" @click="submit" size="mini">保存</el-button>
    </div>
  </el-dialog>

</template>

<script>
import { reservationManageAddMod, delreservationManage, getPurchasOrderList, getgdtSupplier2, getdriverList, getFirmList, getbusLicPlatelist } from '@/apis/purchaseManage/purchaseManage'
import http from '@/utils/http'
export default {
  name: 'purchaseOrderAddAppointOrder',
  props: {
    visible: {
      type: Boolean,
      default: false,
      require: true
    },
    editData: {
      type: Object
    },
    relaItems: {
      type: Array,
      default () {
        return []
      }
    },
    regionOptions: {
      type: Array,
      default () {
        return []
      }
    },
    regionProps: {
      type: Object
    },
    regionArr: {
      type: Array,
      default () {
        return []
      }
    },
    editAble: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  computed: {
    visibleDialog: {
      set (newValue) {
        this.$emit('update:visible', newValue)
      },
      get () {
        return this.visible
      }
    }
  },
  data () {
    return {
      supplierList: [],
      driverList: [],
      busLicPlateList: [],
      firmList: [],
      compList: [],
      loading: false,
      userName: '',
      submitForm: {
        orderNumber: '',
        creatDate: '',
        incomingTime: '',
        carrier: '',
        driver: '',
        driverPhone: '',
        persName: '',
        driverID: '',
        factory: '',
        firm: '',
        compId: '',
        relaId: '',
        model: '',
        receivEnterprise: '成都宏基建材股份有限公司',
        receivSite: '',
        netWeight: '',
        netWeight2: '',
        purchasingOrderId: '',
        reservationId: '',
        roughWeight: '',
        roughWeight2: '',
        roughWeightTime: '',
        startTime: '',
        state: '2',
        tare: '',
        tare2: '',
        tareTime: '',
        unloadEndTime: '',
        unloadStartTime: '',
        voucherNowRelativeUrls: '',
        waybillNumber: '',
        planArrivalTime: '',
        leaveFactoryTime: '',
        plateNumber: '',
        supplier: '',
        supplierId: '',
        planNum: '',
        materialName: '',
        sendDate: '',
        fileList: [],
        http: http,
        url: http.baseUrl + '/basefun/fdfscs/uploadOne',
        supplierItems: ''
      },
      dialogTitle: '',
      rules: {
        driver: [
          { required: true, message: '该属性为必填', trigger: 'blur' }
        ],
        driverPhone: [
          { required: true, message: '该属性为必填', trigger: 'blur' }
        ],
        persId: [
          { required: true, message: '该属性为必填', trigger: 'blur' }
        ],
        planArrivalTime: [
          { required: true, message: '该属性为必填', trigger: 'blur' }
        ]
      },
      stateList: [
        { label: '拒收', value: '0' }, { label: '退货', value: '1' }, { label: '正常', value: '2' }]
    }
  },
  methods: {
    uploadFile (content) {
      const formData = new FormData()
      formData.append('file', content.file)
      http.postForm(this.url, formData).then((res) => {
        this.submitForm.voucherNowRelativeUrls = res.data.nowRelatUrl
        console.log(555, this.submitForm.voucherNowRelativeUrls)
        // this.loading = false
      })
    },
    handleItemChange (val) {
    },
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    handlePreview (file) {
      console.log(file)
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    removeMonRow (index, id, rows) { // 删除改行
      this.$confirm('您确定要删除该数据?', '删除数据', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        if (id) {
          delreservationManage({
            ids: [id]
          }).then(res => {
            if (res.code == 200) {
              this.$message.success('删除成功！')
              rows.splice(index, 1)
            }
          })
        } else {
          rows.splice(index, 1)
        }
      })
    },
    // async firmChange (val) {
    //   this.submitForm.firm = ''
    //   this.submitForm.relaId = ''
    //   console.log(JSON.stringify(val) + '供货厂商firmCode的数据')
    //   for (let i = 0; i < this.firmList.length; i++) {
    //     if (val == this.firmList[i].relaId) {
    //       console.log(JSON.stringify(this.firmList[i].firm) + '供货厂商的数据')
    //       this.submitForm.firm = this.firmList[i].firm
    //     }
    //   }
    // },
    async firmChange (val) {
      this.submitForm.firm = ''
      // this.submitForm.compId = ''
      console.log(JSON.stringify(val) + '供货厂商compId的数据')
      for (let i = 0; i < this.firmList.length; i++) {
        if (val == this.firmList[i].compId) {
          console.log(JSON.stringify(this.firmList[i].compName) + '供货厂商的数据')
          this.submitForm.firm = this.firmList[i].compName
        }
      }
    },
    // 驾驶员
    async driverChange (val) {
      console.log(JSON.stringify(val) + '驾驶员driverID的数据')
      for (let i = 0; i < this.driverList.length; i++) {
        if (val == this.driverList[i].persId) {
          console.log(JSON.stringify(this.driverList[i].phone) + '驾驶员手机的数据')
          this.submitForm.driverPhone = this.driverList[i].phone
          this.submitForm.driver = this.driverList[i].persName
        }
      }
    },
    async plateNumberChange (val) {
      console.log(JSON.stringify(val) + '车牌号vehiId的数据')
      for (let i = 0; i < this.busLicPlateList.length; i++) {
        if (val == this.busLicPlateList[i].vehiId) {
          console.log(JSON.stringify(this.busLicPlateList[i].plateNumber) + '车牌号的数据')
          this.submitForm.plateNumber = this.busLicPlateList[i].busLicPlate
        }
      }
    },
    submit () {
      const param = {
        orderNumber: this.submitForm.orderNumber,
        creatDate: this.submitForm.creatDate,
        // incomingTime: this.submitForm.incomingTime,
        carrier: this.submitForm.carrier,
        driver: this.submitForm.driver,
        driverPhone: this.submitForm.driverPhone,
        // factory: this.submitForm.factory,
        model: this.submitForm.model,
        materialName: this.submitForm.materialName,
        firm: this.submitForm.firm,
        compId: this.submitForm.compId,
        // netWeight: this.submitForm.netWeight,
        // netWeight2: this.submitForm.netWeight2,
        planArrivalTime: this.submitForm.planArrivalTime,
        plateNumber: this.submitForm.plateNumber,
        purchasingOrderId: this.submitForm.purchasingOrderId,
        receivEnterprise: this.submitForm.receivEnterprise,
        receivSite: this.submitForm.receivSite,
        reservationId: this.submitForm.reservationId,
        // roughWeight: this.submitForm.roughWeight,
        // roughWeight2: this.submitForm.roughWeight2,
        // roughWeightTime: this.submitForm.roughWeightTime,
        // startTime: this.submitForm.startTime,
        // startTimeEnd: this.submitForm.startTimeEnd,
        // startTimeStart: this.submitForm.startTimeStart,
        state: this.submitForm.state,
        supplier: this.submitForm.supplier
        // tare: this.submitForm.tare,
        // tare2: this.submitForm.tare2,
        // tareTime: this.submitForm.tareTime,
        // unloadEndTime: this.submitForm.unloadEndTime,
        // unloadStartTime: this.submitForm.unloadStartTime,
        // voucherNowRelativeUrls: this.submitForm.voucherNowRelativeUrls,
        // waybillNumber: this.submitForm.waybillNumber
      }

      this.$refs.addForm.validate((valid) => {
        if (valid) {
          reservationManageAddMod(param).then(res => {
            if (res.code === 200) {
              if (this.submitForm.reservationId != '') {
                this.$message.success('修改成功')
              } else {
                this.$message.success('添加成功')
              }
              this.visibleDialog = false
              this.handlerClose() // 添加成功自动关闭新增弹出狂
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    handlerOpen () {
      this.userName = this.$store.state.account.loginInfo.loginName
      this.submitForm.supplier = this.userName
      console.log(JSON.stringify(this.userName) + 'handlerOpen打印purchaseOrderAddAppointOrder+this.userName')
      if (this.editAble) {
        this.dialogTitle = this.editData ? '新建预约单' : '编辑预约单'
      } else {
        this.dialogTitle = '生成预约单'
      }
      if (this.editData) {
        console.log(JSON.stringify(this.editData) + '生成预约单传过来的数据')
        Object.keys(this.submitForm).forEach((key) => {
          const value = this.editData[key]
          console.log(value + '订单传过来的数据value')
          if (value) this.submitForm[key] = value
        })
      }
    },
    handlerClose () {
      this.$emit('update:editData', null)
      this.submitForm = {
        orderNumber: '',
        creatDate: '',
        incomingTime: '',
        carrier: '',
        driver: '',
        driverPhone: '',
        factory: '',
        model: '',
        firm: '',
        netWeight: '',
        netWeight2: '',
        planArrivalTime: '',
        plateNumber: '',
        purchasingOrderId: '',
        receivEnterprise: '成都宏基建材股份有限公司',
        receivSite: '',
        reservationId: '',
        roughWeight: '',
        roughWeight2: '',
        roughWeightTime: '',
        startTime: '',
        startTimeEnd: '',
        startTimeStart: '',
        state: '2',
        supplier: '',
        tare: '',
        tare2: '',
        tareTime: '',
        unloadEndTime: '',
        unloadStartTime: '',
        voucherNowRelativeUrls: '',
        waybillNumber: ''
      }
      if (this.$refs[' form'] !== undefined) {
        this.$refs.form.resetFields()
      }
    }
  },
  mounted () {
    this.userName = this.$store.state.account.loginInfo.loginName
    this.submitForm.supplier = this.userName
    console.log(this.userName + '打印purchaseOrderAddAppointOrder+this.userName')
    getPurchasOrderList({})
      .then((res) => {
        if (res.code == 200) {
          // this.supplierList = res.data.rows
        }
      })
      .catch((res) => {
        console.log(res)
      })
    getdriverList({})
      .then((res) => {
        if (res.code == 200) {
          console.log(JSON.stringify(res.data.rows) + '驾驶员的数据')
          this.driverList = res.data.rows
        }
      })
      .catch((res) => {
        console.log(res)
      })
      // 关联供应商
    getgdtSupplier2().then(res => {
      console.log(666, res)
      this.supplierList = res.data.rows
    })
    // 车牌号下拉
    getbusLicPlatelist().then(res => {
      console.log(666, res)
      this.busLicPlateList = res.data.rows
    })
    // 供货厂商列表
    getFirmList().then(res => {
      console.log('供货厂商列表', res)
      this.submitForm.firm = ''
      this.submitForm.relaId = ''
      this.firmList = res.data.rows
    })
  }
}
</script>

<style scoped>

</style>
