import { render, staticRenderFns } from "./purchaseAppointmentOrderDetail.vue?vue&type=template&id=2b932663&scoped=true&"
import script from "./purchaseAppointmentOrderDetail.vue?vue&type=script&lang=js&"
export * from "./purchaseAppointmentOrderDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b932663",
  null
  
)

export default component.exports