<template>
  <el-dialog v-bind="{title:dialogTitle,width:'860px'}"
             @open="handlerOpen"
             @close="handlerClose"
             :visible.sync="visibleDialog">
    <el-form :inline="true" ref="form" size="mini" label-position="right"
             label-width="200px"
             :rules="rules"
             :model="submitForm"
             style="margin-top: 20px"
    >
      <el-row>
        <el-form-item label="订单编号：" prop="orderNumber">
          <el-input v-model="submitForm.orderNumber" style="width: 180px">
          </el-input>
        </el-form-item>
        <el-form-item label="订单日期：" prop="creatDate">
          <el-input v-model="submitForm.creatDate" style="width: 180px">
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="品种规格：">
          <el-input v-model="submitForm.model" style="width: 180px">
          </el-input>
        </el-form-item>
        <el-form-item label="供应商：" prop="firmCode">
          <el-input v-model="submitForm.supplier" style="width: 180px">
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="供货厂商：" prop="firm">
          <el-input v-model="submitForm.firm" style="width: 180px">
          </el-input>
        </el-form-item>
        <el-form-item label="承运商：" prop="carrier">
          <el-input v-model="submitForm.carrier" style="width: 180px">
          </el-input>

        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="收货企业:" prop="receivEnterprise">
          <el-input v-model="submitForm.receivEnterprise" style="width: 180px">
          </el-input>
        </el-form-item>
        <el-form-item label="收货站点:">
          <el-input v-model="submitForm.receivSite" style="width: 180px">
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="驾驶员:" prop="persId">
          <el-select
            v-model="submitForm.driver"
            placeholder="请选择驾驶员"
            @change="driverChange"
            filterable
            clearable
            style="width: 180px"
          >
            <template v-for="item in driverList">
              <el-option
                v-if="typeof(item.persId) =='string'"
                :key="item.persId"
                :label="item.persName"
                :value="''+item.persId">
              </el-option>
              <el-option
                v-else-if="typeof(item.persId) =='number'"
                :key="item.persId"
                :label="item.persName"
                :value="((''+item.persId).trim() == '')?'':parseInt(item.persId)">
              </el-option>
              <el-option
                v-else
                :key="item.persId"
                :label="item.persName"
                :value="item.persId">
              </el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号:">
          <el-input disabled v-model="submitForm.driverPhone" style="width: 180px">
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="预计到达时间:" prop="planArrivalTime">
          <el-date-picker
            v-model="submitForm.planArrivalTime"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            style="width: 180px"
          />
        </el-form-item>
        <el-form-item label="车牌号:" prop="vehiId">
          <el-select
            v-model="submitForm.plateNumber"
            placeholder="请选择车牌号"
            filterable
            clearable
            style="width: 180px"
          >
            <template v-for="item in busLicPlateList">
              <el-option
                v-if="typeof(item.vehiId) =='string'"
                :key="item.vehiId"
                :label="item.busLicPlate"
                :value="''+item.vehiId">
              </el-option>
              <el-option
                v-else-if="typeof(item.vehiId) =='number'"
                :key="item.vehiId"
                :label="item.busLicPlate"
                :value="((''+item.vehiId).trim() == '')?'':parseInt(item.vehiId)">
              </el-option>
              <el-option
                v-else
                :key="item.vehiId"
                :label="item.busLicPlate"
                :value="item.vehiId">
              </el-option>
            </template>
          </el-select>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="进厂时间:" prop="incomingTime">
          <el-date-picker
            v-model="submitForm.incomingTime"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            style="width: 180px"
          />
        </el-form-item>
        <el-form-item label="出厂时间:">
          <el-date-picker
            v-model="submitForm.leaveFactoryTime"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            style="width: 180px"
          />
        </el-form-item>
        <el-form-item label="出发时间:">
          <el-date-picker
            v-model="submitForm.startTime"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            style="width: 180px"
          />
        </el-form-item>
        <el-form-item label="生产厂家：">
          <el-input v-model="submitForm.factory" style="width: 180px">
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="净重自动:" prop="netWeight">
          <el-input v-model="submitForm.netWeight" style="width: 180px">
          </el-input>
        </el-form-item>
        <el-form-item label="净重调整:">
          <el-input v-model="submitForm.netWeight2" style="width: 180px">
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="毛重自动:" prop="roughWeight">
          <el-input v-model="submitForm.roughWeight" style="width: 180px">
          </el-input>
        </el-form-item>
        <el-form-item label="毛重调整:">
          <el-input v-model="submitForm.roughWeight2" style="width: 180px">
          </el-input>
        </el-form-item>
        <el-form-item label="毛重时间:">
          <el-date-picker
            v-model="submitForm.roughWeightTime"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            style="width: 180px"
          />
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="皮重自动:" prop="tare">
          <el-input v-model="submitForm.tare" style="width: 180px">
          </el-input>
        </el-form-item>
        <el-form-item label="皮重调整:">
          <el-input v-model="submitForm.tare2" style="width: 180px">
          </el-input>
        </el-form-item>
        <el-form-item label="皮重时间:">
          <el-date-picker
            v-model="submitForm.tareTime"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            style="width: 180px"
          />
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="卸料完成时间:" prop="unloadEndTime">
          <el-date-picker
            v-model="submitForm.unloadEndTime"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            style="width: 180px"
          />
        </el-form-item>
        <el-form-item label="开始卸料时间:">
          <el-date-picker
            v-model="submitForm.unloadStartTime"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            style="width: 180px"
          />
        </el-form-item>
        <el-form-item label="状态:" prop="s">
          <el-select v-model="submitForm.state" clearable  style="width: 180px">
            <el-option v-for="item in stateList"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-row>
    </el-form>
  </el-dialog>

</template>

<script>
import { reservationManageAddMod, delreservationManage, getPurchasOrderList, getgdtSupplier2, getdriverList, getbusLicPlatelist, creatWaybillNumber, getFirmList } from '@/apis/purchaseManage/purchaseManage'
import http from '@/utils/http'
export default {
  name: 'purchaseAppointmentOrderMoreDetail',
  props: {
    visible: {
      type: Boolean,
      default: false,
      require: true
    },
    editData: {
      type: Object
    },
    relaItems: {
      type: Array,
      default () {
        return []
      }
    },
    regionOptions: {
      type: Array,
      default () {
        return []
      }
    },
    regionProps: {
      type: Object
    },
    regionArr: {
      type: Array,
      default () {
        return []
      }
    },
    editAble: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  computed: {
    visibleDialog: {
      set (newValue) {
        this.$emit('update:visible', newValue)
      },
      get () {
        return this.visible
      }
    }
  },
  data () {
    return {
      supplierList: [],
      driverList: [],
      busLicPlateList: [],
      firmList: [],
      compList: [],
      loading: false,
      userName: '',
      submitForm: {
        orderNumber: '',
        creatDate: '',
        incomingTime: '',
        carrier: '',
        driver: '',
        driverPhone: '',
        persName: '',
        factory: '',
        model: '',
        firm: '',
        receivEnterprise: '',
        receivSite: '',
        netWeight: '',
        netWeight2: '',
        purchasingOrderId: '',
        reservationId: '',
        roughWeight: '',
        roughWeight2: '',
        roughWeightTime: '',
        startTime: '',
        state: '2',
        tare: '',
        tare2: '',
        tareTime: '',
        unloadEndTime: '',
        unloadStartTime: '',
        voucherNowRelativeUrls: '',
        waybillNumber: '',
        planArrivalTime: '',
        leaveFactoryTime: '',
        plateNumber: '',
        supplier: '',
        supplierId: '',
        planNum: '',
        sendDate: '',
        fileList: [],
        http: http,
        url: http.baseUrl + '/basefun/fdfscs/uploadOne',
        supplierItems: ''
      },
      dialogTitle: '',
      rules: {
        projName: [
          { required: true, message: '请输入项目名称', trigger: 'blur' }
        ]
      },
      stateList: [
        { label: '拒收', value: '0' }, { label: '退货', value: '1' }, { label: '正常', value: '2' }]
    }
  },
  methods: {
    uploadFile (content) {
      const formData = new FormData()
      formData.append('file', content.file)
      http.postForm(this.url, formData).then((res) => {
        this.submitForm.voucherNowRelativeUrls = res.data.nowRelatUrl
        console.log(555, this.submitForm.voucherNowRelativeUrls)
        // this.loading = false
      })
    },
    handleItemChange (val) {
    },
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    handlePreview (file) {
      console.log(file)
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    removeMonRow (index, id, rows) { // 删除改行
      this.$confirm('您确定要删除该数据?', '删除数据', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        if (id) {
          delreservationManage({
            ids: [id]
          }).then(res => {
            if (res.code == 200) {
              this.$message.success('删除成功！')
              rows.splice(index, 1)
            }
          })
        } else {
          rows.splice(index, 1)
        }
      })
    },
    async driverChange (val) {
      console.log(JSON.stringify(val) + '驾驶员driverID的数据')
      for (let i = 0; i < this.driverList.length; i++) {
        if (val == this.driverList[i].persId) {
          console.log(JSON.stringify(this.driverList[i].phone) + '驾驶员手机的数据')
          this.submitForm.driverPhone = this.driverList[i].phone
          this.submitForm.driver = this.driverList[i].persName
        }
      }
    },

    submit () {
      const param = {
        incomingTime: this.submitForm.incomingTime,
        carrier: this.submitForm.carrier,
        driver: this.submitForm.driver,
        driverPhone: this.submitForm.driverPhone,
        factory: this.submitForm.factory,
        model: this.submitForm.model,
        firm: this.submitForm.firm,
        netWeight: this.submitForm.netWeight,
        netWeight2: this.submitForm.netWeight2,
        planArrivalTime: this.submitForm.planArrivalTime,
        plateNumber: this.submitForm.plateNumber,
        purchasingOrderId: this.submitForm.purchasingOrderId,
        receivEnterprise: this.submitForm.receivEnterprise,
        receivSite: this.submitForm.receivSite,
        reservationId: this.submitForm.reservationId,
        roughWeight: this.submitForm.roughWeight,
        roughWeight2: this.submitForm.roughWeight2,
        roughWeightTime: this.submitForm.roughWeightTime,
        startTime: this.submitForm.startTime,
        startTimeEnd: this.submitForm.startTimeEnd,
        startTimeStart: this.submitForm.startTimeStart,
        state: this.submitForm.state,
        supplier: this.submitForm.supplier,
        tare: this.submitForm.tare,
        tare2: this.submitForm.tare2,
        tareTime: this.submitForm.tareTime,
        unloadEndTime: this.submitForm.unloadEndTime,
        unloadStartTime: this.submitForm.unloadStartTime,
        voucherNowRelativeUrls: this.submitForm.voucherNowRelativeUrls,
        waybillNumber: this.submitForm.waybillNumber

      }
      reservationManageAddMod(param).then(res => {
        if (res.code === 200) {
          if (this.submitForm.reservationId != '') {
            this.$message.success('修改成功')
          } else {
            this.$message.success('添加成功')
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handlerOpen () {
      this.userName = this.$store.state.account
      console.log(this.userName + 'handlerOpen打印purchaseAppointmentOrderDetail+purchaseOrder+this.userName')
      if (this.editAble) {
        this.dialogTitle = this.editData ? '新建预约单' : '编辑预约单'
      } else {
        this.dialogTitle = '预约单详情'
      }
      if (this.editData) {
        Object.keys(this.submitForm).forEach((key) => {
          const value = this.editData[key]
          if (value) this.submitForm[key] = value
        })
      }
    },
    handlerClose () {
      this.$emit('update:editData', null)
      this.submitForm = {
        orderNumber: '',
        creatDate: '',
        incomingTime: '',
        carrier: '',
        driver: '',
        driverPhone: '',
        factory: '',
        model: '',
        firm: '',
        netWeight: '',
        netWeight2: '',
        planArrivalTime: '',
        plateNumber: '',
        purchasingOrderId: '',
        receivEnterprise: '',
        receivSite: '',
        reservationId: '',
        roughWeight: '',
        roughWeight2: '',
        roughWeightTime: '',
        startTime: '',
        startTimeEnd: '',
        startTimeStart: '',
        state: '2',
        supplier: '',
        tare: '',
        tare2: '',
        tareTime: '',
        unloadEndTime: '',
        unloadStartTime: '',
        voucherNowRelativeUrls: '',
        waybillNumber: ''
      }
      this.$refs.form.resetFields()
    }
  },
  mounted () {
    this.userName = this.$store.state.account
    console.log(JSON.stringify(this.userName) + 'handlerOpen打印purchaseAppointmentOrderDetail+this.userName')
    getPurchasOrderList({})
      .then((res) => {
        if (res.code == 200) {
          // this.supplierList = res.data.rows
        }
      })
      .catch((res) => {
        console.log(res)
      })
    getdriverList({})
      .then((res) => {
        if (res.code == 200) {
          this.driverList = res.data.rows
        }
      })
      .catch((res) => {
        console.log(res)
      })
      // 关联供应商
    getgdtSupplier2().then(res => {
      console.log(666, res)
      this.supplierList = res.data.rows
    })
    // 车牌号下拉
    getbusLicPlatelist().then(res => {
      console.log(666, res)
      this.busLicPlateList = res.data.rows
    })
    // 生成运单号
    creatWaybillNumber().then(res => {
      console.log('生成运单号', res)
      this.submitForm.waybillNumber = res.data.waybillNumber
    })
    // 供货厂商列表
    getFirmList().then(res => {
      console.log('供货厂商列表', res)
      this.firmList = res.data.rows
    })
  }
}
</script>

<style scoped>

</style>
