<template>
  <page-container>
    <div class="layout">
      <div style="width: 100%;display: flex;align-items: center;">
        <el-form :inline="true" :model="searchModel" style="margin-top: 20px;padding-left: 20px" size="mini">
          <el-form-item label="材料名称:" prop="materialName">
            <el-input v-model="searchModel.materialName"></el-input>
          </el-form-item>
          <el-form-item label="订单日期:">
            <el-date-picker
              v-model="searchModel.sendDateStart"
              type="date"
              placeholder="选择日期时间"
              style="width: 180px"
              value-format="yyyy-MM-dd"
            />
            --
            <el-date-picker
              v-model="searchModel.sendDateEnd"
              type="date"
              placeholder="选择日期时间"
              style="width: 180px"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="submitSearch" :loading="loading">{{ searchText }}
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-close" v-if="loading" @click="loading = false">停止</el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              v-auth="'purchaseManage_purchaseOrder_add'"
              type="primary"
              icon="el-icon-circle-plus-outline"
              @click="dialogEdit = true;addRow()"
            >新增
            </el-button
            >
          </el-form-item>
        </el-form>
      </div>

      <resize-wrap class="flex-1">
        <BaseTable
          id="purchaseOrder"
          ref="xTable1"
          size="mini"
          border
          auto-resize
          resizable
          highlight-current-row
          highlight-hover-row
          export-config
          :seq-config="{startIndex: (tablePage.currentPage - 1) * tablePage.pageSize}"
          :align="allAlign"
          :data="tableData">
          <vxe-table-column type="seq" title="序号" width="60"/>
          <vxe-table-column field="factory" title="生产厂家" show-overflow="title" min-width="120" show-header-overflow="title"/>
          <vxe-table-column field="orderNumber" title="订单编号" min-width="60" show-overflow="title"
                            show-header-overflow="title"  />
          <vxe-table-column field="creatDate" title="订单日期" show-overflow="title" min-width="80" show-header-overflow="title"/>
          <vxe-table-column field="supplier" title="供应商" min-width="60" show-overflow="title"
                            show-header-overflow="title"/>
          <vxe-table-column field="materialName" title="材料名称" min-width="60" show-overflow="title"
                            show-header-overflow="title" />
          <vxe-table-column field="model" title="品种规格" min-width="60" show-overflow="title"
                            show-header-overflow="title" />
          <vxe-table-column field="planNum" title="预计数量" min-width="60" show-overflow="title"
                            show-header-overflow="title" />
          <vxe-table-column field="receivEnterprise" title="收货企业" min-width="100" show-overflow="title"
                            show-header-overflow="title" />
          <vxe-table-column field="receivSite" title="收货地点" min-width="100" show-overflow="title"
                            show-header-overflow="title" />
          <vxe-table-column field="sendDate" title="送货日期开始(精确到日)" min-width="120" show-overflow="title"
                            show-header-overflow="title"/>
          <vxe-table-column title="操作" min-width="180">
            <template v-slot="{ row }">
              <el-button    type="success"  circle size="mini" @click="editAble=true;addAppointOrder(row)" title="生成预约单">生成预约单</el-button>
              <el-button   v-auth="'purchaseManage_purchaseOrder_edit'" type="primary" icon="el-icon-edit" circle size="mini" @click="editAble=true;editRow(row)" title="修改"/>
              <el-button    v-auth="'purchaseManage_purchaseOrder_delete'" type="danger" icon="el-icon-delete" circle size="mini" @click="deleteRow(row)" title="删除"/>
            </template>
          </vxe-table-column>
        </BaseTable>
      </resize-wrap>
      <vxe-pager
        :loading="loading"
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :total="tablePage.total"
        :layouts="['Sizes','PrevPage','JumpNumber','NextPage','FullJump','Total']"
        @page-change="handlePageChange"
      />
      <el-dialog :title="dialogEditTitle"
                 :visible.sync="dialogEdit"
                 :v-if="dialogEdit"
                 :append-to-body="true"
                 :close-on-click-modal="false"
                 :close-on-press-escape="false"
                 :destroy-on-close="false"
                 v-dialogDrag
                 width="860px">

        <el-form :inline="true" ref="addForm" size="mini" label-position="right" label-width="200px"
                 style="margin-top: 20px">
          <el-row>
            <el-form-item label="选择采购计划：" prop="purchasingPlanId">
              <el-select
                v-model="submitForm.purchasingPlanId"
                placeholder="请选择订单"
                @change="planChange"
                filterable
                clearable
                style="width: 180px"
              >
                <template v-for="item in purchasPlanList">
                  <el-option
                    v-if="typeof(item.purchasingPlanId) =='string'"
                    :key="item.purchasingPlanId"
                    :label="item.orderNumber"
                    :value="''+item.purchasingPlanId">
                  </el-option>
                  <el-option
                    v-else-if="typeof(item.purchasingPlanId) =='number'"
                    :key="item.purchasingPlanId"
                    :label="item.orderNumber"
                    :value="((''+item.purchasingPlanId).trim() == '')?'':parseInt(item.purchasingPlanId)">
                  </el-option>
                  <el-option
                    v-else
                    :key="item.purchasingPlanId"
                    :label="item.orderNumber"
                    :value="item.purchasingPlanId">
                  </el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="订单编号:" props="orderNumber">
              <el-input
                v-model="submitForm.orderNumber"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="供应商:" props="supplier">
              <el-input
                v-model="submitForm.supplier"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="联系人:" props="relationName">
              <el-input
                v-model="submitForm.relationName"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="联系电话:" props="tel">
              <el-input
                v-model="submitForm.tel"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="生产厂家:" props="factory">
              <el-input
                v-model="submitForm.factory"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="材料名称:" props="materialName">
              <el-input
                v-model="submitForm.materialName"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="品种规格:" props="model">
              <el-input
                v-model="submitForm.model"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>

            <el-form-item label="预计数量:" props="planNum">
              <el-input
                v-model="submitForm.planNum"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="订单生成日期:">
              <el-date-picker
                v-model="submitForm.creatDate"
                type="datetime"
                placeholder="选择日期时间"
                default-time="00:00:00"
                value-format="yyyy-MM-dd"
                style="width: 180px"
              />
            </el-form-item>
            <el-form-item label="送货日期:" props="sendDate">
              <el-date-picker
                v-model="submitForm.sendDate"
                type="datetime"
                placeholder="选择日期时间"
                default-time="00:00:00"
                value-format="yyyy-MM-dd"
                style="width: 180px"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="收货企业:" props="receivEnterprise" >
              <el-input
                v-model="submitForm.receivEnterprise"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="收货站点:" prop="receivSite">
              <el-input
                v-model="submitForm.receivSite"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogBusEdit = false" size="mini">取消</el-button>
          <el-button type="primary" @click="onSubmitOrderEdit()" size="mini">保存</el-button>
        </div>
      </el-dialog>
      <purchase-OrderAddAppointOrder :visible.sync="dialogAppointOrderEdit" :edit-data.sync="appointOrderEditModel"  :rela-items="relaItems" :region-options="regionOptions" :region-props="regionProps"/>
      <edit-SupplyDetail :visible.sync="dialogProjEdit" :edit-data.sync="projEditModel"  :rela-items="relaItems" :region-options="regionOptions" :region-props="regionProps"/>
    </div>
  </page-container>
</template>

<script>
import purchaseOrderAddAppointOrder from './purchaseOrderAddAppointOrder'
import editSupplyDetail from './purchaseOrderSupplyDetail'
import { exportExcelContract } from '@/apis/saleManage/contractMan'
import { gainRegi } from '@/apis/common'
import XEUtils from 'xe-utils'
import { exportToxlsx } from '@/utils/tools'
import { mapState } from 'vuex'
import http from '@/utils/http'

import { getpurchasOrderpage, getpurchasOrderone, delpurchasOrder, purchasOrderAddMod, getPurchasPlanList, getgdtSupplier2 } from '@/apis/purchaseManage/purchaseManage'

export default {
  name: 'purchaseOrder',
  data () {
    return {
      editAble: true,
      purchasPlanList: [],
      fileList: [],
      http: http,
      url: http.baseUrl + '/basefun/fdfscs/uploadOne',
      regionArr: [],
      editMode: 'add',
      dialogProjEdit: false,
      regionOptions: [],
      regionProps: {
        // multiple: true,
        checkStrictly: true,
        lazy: true,
        lazyLoad (node, resolve) {
          const level = node.level
          if (level === 1) {
            resolve(node.children[0].label == '直辖区' ? [] : node.children)
            // resolve(node.children)
          }
          if (level === 2 && node.data.code) {
            if (node.children && node.children.lenght > 0) {
              resolve(node.children)
            } else {
              setTimeout(() => {
                gainRegi({
                  isMapJson: false,
                  regiLevel: node.level,
                  regiCodes: [node.data.code]
                }).then((res) => {
                  if (res.code == 200) {
                    const nodeArr = res.data[0].regiCountrs.map((val) => ({
                      value: val.regiCountrId,
                      label: val.countrName,
                      code: val.countrCode,
                      leaf: true
                    }))
                    resolve(nodeArr)
                  }
                })
              })
            }
          } else if (level == 3) {
            resolve([])
          }
        }
      },
      supplierList: [],
      submitForm: {
        creatDate: '',
        factory: '',
        materialName: '',
        model: '',
        relationName: '',
        tel: '',
        orderNumber: '',
        planNum: '',
        purchasingOrderId: '',
        purchasingPlanId: '',
        receivEnterprise: '',
        receivSite: '',
        sendDate: '',
        supplier: '',
        supplierId: ''
      },
      dialogEditTitle: '新增订单',
      dialogEdit: false,
      searchModel: {
        sendDateEnd: '',
        sendDateStart: '',
        materialName: '',
        model: ''
      },
      searchText: '查询',
      loading: false,
      tableData: [],
      allAlign: 'center',
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 30,
        align: 'right',
        pageSizes: [10, 20, 50, 100, 200, 500],
        layouts: ['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total'],
        perfect: true
      },
      relaList: [],
      relaItems: [],
      projEditModel: {},
      userName: '',
      dialogAppointOrderEdit: false,
      appointOrderEditModel: {}

    }
  },
  computed: {
    ...mapState('account', ['loginInfo', 'userInfo'])
  },
  methods: {
    uploadFile (content) {
      const formData = new FormData()
      formData.append('file', content.file)
      http.postForm(this.url, formData).then((res) => {
        this.submitForm.voucherNowRelativeUrls = res.data.nowRelatUrl
        console.log(555, this.submitForm.voucherNowRelativeUrls)
        // this.loading = false
      })
    },
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    handlePreview (file) {
      console.log(file)
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    refreshRelaCallback () {
    },
    // 分页变化
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage
      this.tablePage.pageSize = pageSize
      this.refreshData()
    },
    exportTabData () {
      exportExcelContract({
        signDateEnd: '',
        signDateStart: '',
        relaId: '',
        contractStatus: '',
        contractClass: '',
        companyName: ''
      }).then(res => {
        exportToxlsx(res, '订单列表')
      })
    },
    refreshData () {
      this.loading = true
      // this.userName = this.$store.state.account
      this.userName = this.$store.state.account.loginInfo.loginName
      this.submitForm.supplier = this.userName
      console.log(JSON.stringify(this.userName) + 'refreshData打印purchaseOrder+this.userName')
      getpurchasOrderpage({
        ...this.searchModel,
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize
      }).then(res => {
        this.loading = false
        if (res.code == 200) {
          const data = res.data
          this.tableData = data.rows
          this.tablePage.total = data.totalSize
        } else {
          this.tableData = []
          this.tablePage.total = 0
        }
      }).catch(error => {
        console.log(error)
        //  this.logining = false;
      })
    },
    addRow () {
      this.getPurchasPlanList()
      this.editMode = 'add'
      this.dialogEditTitle = '新增采购订单'
    },
    onSubmitOrderEdit () {
      purchasOrderAddMod(this.submitForm).then(res => {
        if (res.code === 200) {
          if (this.submitForm.purchasingOrderId != '') {
            this.$message.success('修改成功')
          } else {
            this.$message.success('添加成功')
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    async planChange (val) {
      console.log(JSON.stringify(val) + '采购计划purchasingPlanId的数据')
      for (let i = 0; i < this.purchasPlanList.length; i++) {
        if (val == this.purchasPlanList[i].purchasingPlanId) {
          console.log(JSON.stringify(this.purchasPlanList[i].model) + '订单品种规格的数据')
          console.log(JSON.stringify(this.purchasPlanList[i].purchasingPlanId) + '订单流水的数据')
          this.submitForm.purchasingPlanId = this.purchasPlanList[i].purchasingPlanId
          this.submitForm.factory = this.purchasPlanList[i].factory
          this.submitForm.model = this.purchasPlanList[i].model
          this.submitForm.orderNumber = this.purchasPlanList[i].orderNumber
          this.submitForm.creatDate = this.purchasPlanList[i].creatDate
          this.submitForm.materialName = this.purchasPlanList[i].materialName
        }
      }
    },
    getPurchasPlanList () {
      getPurchasPlanList({})
        .then((res) => {
          if (res.code == 200) {
            console.log(JSON.stringify('选择采购计划列表'))
            this.purchasPlanList = res.data.rows
          }
        })
        .catch((res) => {
          console.log(res)
        })
    },
    addAppointOrder (row) {
      const rowData = {}
      console.log(JSON.stringify(row) + '订单单条的数据row1')
      XEUtils.objectEach(row, (item, key) => {
        rowData[key] = row[key] ? row[key].toString() : ''
        // console.log(JSON.stringify(rowData[key]) + '订单单条的数据rowData[key]')
      })
      this.appointOrderEditModel = Object.assign({}, rowData)
      this.dialogAppointOrderEdit = true
      // })
    },
    editRow (row) {
      getpurchasOrderone({ id: row.purchasingOrderId }).then(res => {
        //  this.modifyDetailRow = row
        this.dialogEditTitle = '编辑采购订单'
        const rowData = {}
        XEUtils.objectEach(this.submitForm, (item, key) => {
          rowData[key] = row[key] ? row[key].toString() : ''
        })
        this.submitForm = Object.assign({}, rowData)
        this.dialogEdit = true
      })
    },
    deleteRow (row) {
      this.$confirm('确定删除该采购订单信息?', '删除采购订单', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        delpurchasOrder({
          ids: [row.purchasingOrderId]
        }).then(res => {
          if (res.code === 200) {
            this.$message.success('删除成功！')
            this.refreshData()
          } else { this.$message.error(res.msg) }
        })
      })
    },
    submitSearch () {
      this.loading = true
      this.tablePage.currentPage = 1
      this.refreshData()
    },
    handleItemChange (val) {
    }
  },
  mounted () {
    this.userName = this.$store.state.account
    console.log(JSON.stringify(this.userName) + '打印purchaseOrder+this.userName')
    this.refreshData()
    // 20:企业下供应商
    getPurchasPlanList({})
      .then((res) => {
        if (res.code == 200) {
          // this.supplierList = res.data.rows
        }
      })
      .catch((res) => {
        console.log(res)
      })
    // 关联供应商
    getgdtSupplier2().then(res => {
      console.log(666, res)
      this.supplierList = res.data.rows
    })
  },
  components: {
    purchaseOrderAddAppointOrder,
    editSupplyDetail
  }
}
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  overflow: hidden;
  background-color: white;
}
#none_proj{
  color:#e6a23c;
}

</style>
